<template lang="pug">
  .settings-page
    TheNavBar.the-nav-bar
    .feed-list__container
      h1.feed-list__title Feeds:
      // <span style="font-size: 2rem;"> Settings:</span>
      .feed-buttons
        img.feed-buttons__head-icon( src="../assets/icons/round-subdirectory_arrow_right-24px.svg" )
        AppCircleButton.feed-buttons__button( @click="addFeed" color="#FF6600" label="Add Feed" )
          img.feed-buttons__icon( src="../assets/icons/round-add-24px.svg" )
        AppCircleButton( @click="sendFeedEmail" color="rgb(0, 140, 255)" label="send email" )
          img.feed-buttons__icon( src="../assets/icons/round-email-24px.svg" v-show="!emailRequestPending" )
          img.feed-buttons__icon.feed-buttons__icon--loader( src="../assets/icons/round-cached-24px.svg" v-show="emailRequestPending" )
      br
      b.feed-no-content-message(v-if="loading") Loading ...
      b.feed-no-content-message(v-else-if="feeds.length === 0") No feeds
      ul.feed-list
        li.feed-list__item( v-for="feed in feeds" )
          a.feed-list__item__title( :href="getUrlOrigin(feed.url)" :class="{'feed-list__item__title--invalid': feed.valid === false}" ) {{feed.title || getUrlHostname(feed.url)}}
          span.feed-list__delete-item( @click="deleteFeed(feed.id)" title="delete feed item" style="color: red;" ) ✖︎
</template>

<script>
import isURL from 'validator/lib/isURL'

import { db, auth } from '@/firebase.js'

import TheNavBar from '@/components/TheNavBar.vue'
import AppCircleButton from '@/components/AppCircleButton.vue'

const prefixHttpsToUrl = (url) => (url.indexOf('://') === -1) ? 'https://' + url : url

export default {
  name: 'feeds',
  components: {
    TheNavBar,
    AppCircleButton
  },
  data () {
    return {
      user: {},
      feeds: [],
      newEmail: '',
      loading: true,
      emailFrequency: 1,
      emailRequestPending: false
    }
  },
  created () {
    auth.onAuthStateChanged(user => {
      if (user) {
        this.user = user
        this.setFeedChangeListener() // setup listener for feed data
        return
      }
      this.$router.push({ name: 'home' })
    })
  },
  methods: {
    getUrlOrigin (url) {
      if (!url) return ''
      try { return (new URL(url).origin) } catch (e) { return url }
    },
    getUrlHostname (url) {
      if (!url) return ''
      try { return (new URL(url).hostname) } catch (e) { return url }
    },
    addFeed () {
      if (!this.user.uid) return alert('not logged in')
      const rssUrl = prefixHttpsToUrl(prompt('Feed URL:').trim())

      if (!isURL(rssUrl)) return alert('feed is improperly formatted')
      return db
        .collection('users')
        .doc(this.user.uid)
        .collection('feeds')
        .add({ url: rssUrl })
        .then(() => {
          this.$ga.event({
            eventCategory: 'feed',
            eventAction: 'Add feed',
          })
        })
    },
    deleteFeed (feedId) {
      if (!this.user.uid) return alert('not logged in')
      return db
        .collection('users')
        .doc(this.user.uid)
        .collection('feeds')
        .doc(feedId)
        .delete()
        .then(() => {
          this.$ga.event({
            eventCategory: 'feed',
            eventAction: 'Delete feed',
          })
        })
    },
    setFeedChangeListener () {
      if (!this.user.uid) return alert('not logged in')
      return db
        .collection('users')
        .doc(this.user.uid)
        .collection('feeds')
        .onSnapshot(collection => {
          // if (!collection.exists) {
          //   console.log('No such collectionument!')
          //   return
          // }
          this.loading = false
          this.feeds = collection.docs.map(doc => ({ id: doc.id, ...doc.data() }))
        })
    },
    sendFeedEmail () {
      if (!this.user.uid) return alert('not logged in')
      if (!confirm("This will trigger an email right now but you'll still get the regular one, proceed?")) return
      this.emailRequestPending = true
      return fetch(`https://us-central1-${process.env.VUE_APP_FB_PROJECT_API}.cloudfunctions.net/sendMailToUser?uid=${this.user.uid}`, {
        method: 'POST'
      })
        .then(async (resp) => {
          if (!resp.ok) throw new Error((await resp.json())['error'] || 'something went wrong')
          this.emailRequestPending = false
        })
        .then(() => {
          this.$ga.event({
            eventCategory: 'feed',
            eventAction: 'Send feed email',
          })
        })
        .catch(e => { this.emailRequestPending = false; alert(e.message) })
    }
  }
}
</script>

<style lang="scss" scoped>
.settings-page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 60px 140px;

  @media (max-width: 600px) {
    padding: 60px 20px;
  }
}

.the-nav-bar {
  position: absolute;
  top: 30px;
  right: 40px;
  color: white;
  font-weight: bold;

  @media (max-width: 600px) {
    right: 20px;
  }
}

.feed-buttons {
  margin: 0 -10px;
  margin-bottom: 10px;

  &__head-icon {
    height: 40px;
    width: 40px;
    padding-left: 25px;
    margin-right: -10px; // quick fix
  }

  &__button {
    margin: 0 15px;
  }

  &__icon {
    height: 30px;

    &--loader {
      position: relative;
      top: 0;
      animation: rotation 1.5s infinite cubic-bezier(.63,.36,.41,.68);
      @keyframes rotation {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(-359deg);
        }
      }
    }
  }
}

.feed-no-content-message {
  padding-left: 20px;
  font-weight: bold;
}

.feed-list {
  list-style: none;
  padding: 0 20px;
  font-size: 1.2rem;
  line-height: 1em;
  margin: -1em 0;

  &__container {
    text-align: left;
    max-width: 460px;
  }

  &__title {
    font-size: 6rem;
    margin: 0em 0;
  }

  &__item {
    font-weight: 800;
    margin: 1em 0;

    &__title {
      color: inherit;
      text-decoration: none;
      border-bottom: 3px solid orangered;

      &--invalid {
        color: red;
      }
    }

    &:hover {
      .feed-list__delete-item {
        display: inline;
      }
    }
  }

  &__delete-item {
    display: none;
    cursor: pointer;
  }
}
</style>
