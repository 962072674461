<template lang="pug">
  nav.nav-bar
    a.page-link( href="mailto:will@feedlivery.com" ) Help/Suggestions
    router-link.page-link( v-if="$route.name !== 'settings'" :to="{name: 'settings'}" ) Settings
    router-link.page-link( v-if="$route.name !== 'feeds'" :to="{name: 'feeds'}" ) Feeds
    a.page-link( @click="logout" ) Logout
      img.page-link__icon( src="../assets/icons/round-exit_to_app-24px.svg" )
</template>

<script>
import { auth, db } from '@/firebase.js'

export default {
  name: 'TheNavBar',
  components: {},
  data () {
    return {}
  },
  methods: {
    logout () {
      return auth.signOut()
        .then(() => {
          this.$router.push({ name: 'home' })
        })
        .catch(err => {
          alert(err.message)
          console.error(err)
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.nav-bar {
  margin: 0 -20px;

  & > * {
    display: inline-block;
    margin: 0 20px;
    // opacity: 0.71;
  }
}

.page-link {
  color: inherit;
  text-decoration: none;
  cursor: pointer;

  &__icon {
    height: 1em;
    vertical-align: top;
    margin: 0 5px;
  }
}
</style>
