<template lang="pug">
  .button-container
      .circle-button( v-bind="$attrs" v-on="listeners" :style="`background-color: ${color}`" )
        slot
      span.circle-button__label {{label}}
</template>

<script>
export default {
  name: 'AppCircleButton',
  props: {
    color: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    }
  },
  inheritAttrs: false,
  computed: {
    listeners () {
      return {
        ...this.$listeners,
        input: e => this.$emit('input', e.target.value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.button-container {
  color: white;
  display: inline-block;
  position: relative; // for label positioning

  .circle-button {
    $button-size: 3em;

    height: $button-size;
    width: $button-size;
    box-sizing: border-box;
    appearance: none;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    font-weight: bolder;
    border-radius: 50%;
    border: none;
    transition: transform .3s ease-out;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &__label {
      font-size: 0.8em;
      opacity: 0; // hide by default
      margin: 5px;
      transition: opacity .3s ease-out;
      position: absolute;
      top: $button-size + 1em; // get text below button
      left: $button-size / 2; // start text in middle of button
      transform: translateX(-50%); // and center it
      white-space: nowrap; // make sure it doesn't wrap
    }

    &:active {
      transform: scale(1.2)
    }
  }
  &:hover {
    .circle-button__label {
      opacity: 0.81;
    }
  }
}
</style>
